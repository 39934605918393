import { Injectable, inject } from '@angular/core';
import {
  Aircraft,
  AircraftPromotionRequest,
  AircraftService as CoreAircraftService
} from '@garmin-avcloud/avcloud-web-utils';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  private userAircraftCache$: Observable<Aircraft[]> | null = null;
  private readonly coreAircraftService = inject(CoreAircraftService);

  getAircraftById(aircraftUuid: string): Observable<Aircraft> {
    if (this.userAircraftCache$ == null) {
      this.userAircraftCache$ = this.getInitialAircraftData();
    }

    return this.userAircraftCache$.pipe(
      map((aircraftList) => {
        const match = aircraftList.find(
          (aircraft) => aircraft.aircraftUuid === aircraftUuid || aircraft.masterAircraftUuid === aircraftUuid
        );

        if (match == null) {
          throw new Error('No aircraft found with the specified ID');
        }

        return match;
      })
    );
  }

  promoteAircraft(aircraftUuid: string, promoteRequest?: AircraftPromotionRequest): Observable<Aircraft> {
    return this.coreAircraftService.promoteAircraft(aircraftUuid, promoteRequest);
  }

  private getInitialAircraftData(): Observable<Aircraft[]> {
    return this.coreAircraftService.getAllAircraft(true).pipe(shareReplay(1));
  }
}
