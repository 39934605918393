<ng-template #loading>
  @for (_ of enablementsCartItems; track _) {
    <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
  }
</ng-template>

<ng-template #error>
  @for (enablementCartItem of enablementsCartItems; track enablementCartItem.id) {
    <fly-cart-item-enablement
      [item]="enablementCartItem"
      [skuProduct]="skuProductsMap.get(enablementCartItem.product.partKey)"
      [hasError]="hasError"
      [cartItemMode]="cartItemMode"
      (removeCartItem)="removeCartItem.emit($event)"
      (removingCartItem)="removingCartItem.emit($event)">
    </fly-cart-item-enablement>
  }
</ng-template>

@if (enablementPricingResponseArray$ | flyObservableStatus | async; as status) {
  @if (status.status === ObservableStatus.LOADING) {
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  }
  @if (status.status === ObservableStatus.ERROR) {
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  }
  @if (status.status === ObservableStatus.LOADED && status.value != null) {
    @for (enablementCartItem of enablementsCartItems; track enablementCartItem.id) {
      <fly-cart-item-enablement
        [cartItemMode]="cartItemMode"
        [skuProduct]="skuProductsMap.get(enablementCartItem.product.partKey)"
        [item]="enablementCartItem"
        [price]="enablementCartItem.orderPrice"
        [enablementPricingResponseArray]="status.value"
        (priceByCartItemId)="priceByCartItemId.emit($event)"
        (removeCartItem)="removeCartItem.emit($event)"
        (removingCartItem)="removingCartItem.emit($event)">
      </fly-cart-item-enablement>
    }
  }
}
