import { Component, Input, OnInit } from '@angular/core';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { AvdbPricingProductSeries } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product-series.model';
import { AvdbPricingProductType } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product-type.model';
import { AvdbPricingProduct } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product.model';

@Component({
  selector: 'fly-coverage-details-avdb-multiple-series',
  templateUrl: './coverage-details-avdb-multiple-series.component.html',
  styleUrls: ['./coverage-details-avdb-multiple-series.component.scss']
})
export class CoverageDetailsAvdbMultipleSeriesComponent implements OnInit {
  // The type is used to display what the type of the AVDB Product is
  @Input({ required: true }) avdbPricingProductType: AvdbPricingProductType;
  // The array of products should only contain a max of two products, one annual and one monthly
  // The products are expected to share a series with the only difference being the price, and part key
  @Input({ required: true }) avdbPricingProducts: AvdbPricingProduct[];
  isLoading: boolean = true;
  coverageMapUrl: string | null = null;
  seriesToExpandDescriptionFor: Set<AvdbPricingProductSeries> = new Set<AvdbPricingProductSeries>();

  constructor(readonly siteConfigService: SiteConfigService) {}

  ngOnInit(): void {
    this.siteConfigService.getSiteConfig().subscribe((config) => {
      this.coverageMapUrl = `${config.cdnUrl}/img/avdb_type_regions/`;
      this.isLoading = false;
    });
  }
}
