@if (shouldShowDropdown()) {
  <label for="billingAccountSelection">
    <strong> Billing Account </strong>
  </label>
}
@if (shouldShowDropdown()) {
  <g-drop-down
    label="Billing Account"
    [formGroup]="formGroup"
    [hideLabel]="true">
    <select
      [formControl]="formControl"
      id="billingAccountSelection"
      (change)="selectBillingAccount()">
      @if (shouldShowPleaseSelectOption()) {
        <option
          [ngValue]="null"
          disabled>
          Select a Billing Account
        </option>
      }
      @for (account of accounts; track account.id) {
        <option [ngValue]="account.billingAccountId">
          {{ account | flyAccountDisplay }}
        </option>
      }
    </select>
  </g-drop-down>
}
@if (isInvalid) {
  <g-error> Please select a billing account. </g-error>
}
