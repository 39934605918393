import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AvdbPricingBundleType,
  CartItemType,
  cartItemTypeDisplayName,
  SkuProduct
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { CartItemMode } from '../../../../../shared/enums/cart-item-mode-enum';
import { CartAddItemRequest } from '../../../../../shared/models/cart/cart-add-item-request';
import { CartItem } from '../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../shared/models/cart/cart.model';
import { Money } from '../../../../../shared/models/money.model';
import { LegacySubscriptionExpiringResponse } from '../../../../../shared/models/subscriptions/legacy-subscription-expiring-response.model';
import { CartValidationService } from '../../../../../shared/services/cart-validation.service';
import { CartService } from '../../../../../shared/services/cart.service';

enum State {
  Loading,
  Removed,
  Error,
  Loaded
}

@Component({
  selector: 'fly-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnChanges, OnInit {
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() presentInCurrentCart: boolean;
  @Input() skuProduct: SkuProduct;
  @Input() price?: Money;
  @Input() cartItemType: CartItemType;
  @Input() cartItem?: CartItem;
  @Input() legacySubscriptionExpiringResponse: LegacySubscriptionExpiringResponse;
  @Input() hasError: boolean;
  @Input() headerText?: string;
  @Input() removeCartItem$: Observable<Cart>;
  @Input() hasCollapsedDetails: boolean = false;
  @Input() kindOfCollapsedDetails: string = '';
  @Output() readonly removeCartItemEvent: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  @Output() readonly performAfterPurchaseActionEvent: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly expandCollapsedDetailsEvent: EventEmitter<boolean> = new EventEmitter();
  protected readonly cartItemTypeDisplayName = cartItemTypeDisplayName;
  readonly CartItemMode = CartItemMode;
  readonly State = State;
  expandCollapsedDetails: boolean = false;
  addToCurrentCartFailed: boolean = false;
  cartItemValidationFailed: boolean = false;
  removeFailed: boolean = false;
  currentState: State = State.Loading;
  removing: boolean = false;
  errorMessage: string;
  yarmouthValidationErrorMessage: string;
  itemForAddingToCurrentCart: CartAddItemRequest;

  constructor(
    readonly cartService: CartService,
    private readonly cartValidationService: CartValidationService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    switch (this.cartItemMode) {
      case CartItemMode.CHECKOUT:
        this.errorMessage =
          'An error occurred while processing this item. Please remove this item and try again, ' +
          'or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.';
        break;
      default: {
        this.errorMessage =
          'An error occurred while loading this item. Please refresh ' +
          'or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.';
        break;
      }
    }

    if (
      (('cartItem' in changes && this.cartItem != null) ||
        ('legacySubscriptionExpiringResponse' in changes && this.legacySubscriptionExpiringResponse != null)) &&
      (this.cartItemType === CartItemType.DISCOUNT_CODE || ('price' in changes && this.price != null))
    ) {
      this.currentState = State.Loaded;
    }

    if ('hasError' in changes && this.hasError) {
      this.currentState = State.Error;
    }
  }

  ngOnInit(): void {
    if (this.cartItem != null) {
      this.cartValidationService
        .getCartItemError(this.cartItem.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((validation) => {
          if (validation != null) {
            this.cartItemValidationFailed = validation.error != null;
            this.yarmouthValidationErrorMessage = validation.error != null ? validation.error.message : '';
          } else {
            this.cartItemValidationFailed = false;
            this.yarmouthValidationErrorMessage = '';
          }
        });
    }

    this.createItemForAddingToCurrentCart();
  }

  expandOrCollapseDetails(): void {
    this.expandCollapsedDetails = !this.expandCollapsedDetails;
    this.expandCollapsedDetailsEvent.emit(this.expandCollapsedDetails);
  }

  removeCartItem(): void {
    this.removeFailed = false;
    this.removingCartItem.emit(true);
    this.removing = true;

    this.removeCartItem$.subscribe({
      next: () => {
        this.removeCartItemEvent.emit(this.cartItem);
        this.removingCartItem.emit(false);
        this.currentState = State.Removed;
        this.removing = false;
      },
      error: () => {
        this.removeFailed = true;
        this.removingCartItem.emit(false);
        this.removing = false;
      }
    });
  }

  performAfterPurchaseAction(): void {
    this.performAfterPurchaseActionEvent.emit(this.cartItem);
  }

  private createItemForAddingToCurrentCart(): void {
    if (this.currentState === State.Loaded && this.cartItemMode === CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION)
      this.itemForAddingToCurrentCart = {
        itemType: this.cartItemType,
        partNumber: this.skuProduct.sku,
        aircraftID:
          this.skuProduct.bundleType === AvdbPricingBundleType.PILOTPAK ||
          this.skuProduct.bundleType === AvdbPricingBundleType.ONEPAK
            ? this.legacySubscriptionExpiringResponse?.tailNumber
            : undefined,
        deviceID:
          this.skuProduct.productType === CartItemType.DATABASE ||
          this.skuProduct.productType === CartItemType.ENABLEMENT ||
          this.skuProduct.bundleType === AvdbPricingBundleType.DEVICE
            ? this.legacySubscriptionExpiringResponse.devicePk
            : undefined
      };
  }

  private getValidationMessage(hasProblemValidating: boolean): string {
    if (hasProblemValidating) {
      return (
        'A validation error occurred while processing this item. Please remove this item and try again, ' +
        'or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.'
      );
    }

    const linkMessage =
      'To manage this subscription, visit <a routerLink="/subscriptions/databases">Database Subscriptions</a> page.';
    switch (this.skuProduct.bundleType) {
      case AvdbPricingBundleType.ONEPAK:
        return `This aircraft already has an active subscription for this OnePak. ${linkMessage}`;
      case AvdbPricingBundleType.PILOTPAK:
        return `This aircraft already has an active subscription for this PilotPak. ${linkMessage}`;
      case AvdbPricingBundleType.DEVICE:
        return `This device already has an active subscription for this bundle. ${linkMessage}`;
      default:
        return `This device already has an active subscription for this database. ${linkMessage}`;
    }
  }
}
