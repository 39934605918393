@if (currentState === State.InvalidPromoCodeError) {
  <div class="text-center">
    <p class="mb-4"
      >One or more of the provided discount codes are invalid. Please verify or remove the code(s) to checkout.</p
    >
  </div>
}
@if (currentState === State.PurchasingDisabledError) {
  <div class="text-center">
    <p class="mb-4">Purchasing has been disabled on this account.</p>
    <p class="mb-4">Please contact <a routerLink="/aviation-support">Aviation Product Support</a> for assistance.</p>
  </div>
}
@if (currentState === State.GenericError) {
  <div class="text-center">
    <p class="mb-4">An error occurred, and we were unable to process your order. </p>
    <p class="mb-4">Please contact <a routerLink="/aviation-support">Aviation Product Support</a> for assistance.</p>
  </div>
}
