<ng-template #loading>
  <fly-full-page-loader> </fly-full-page-loader>
</ng-template>

@if (asyncData$ | flyObservableStatus | async; as status) {
  <div>
    <g-heading
      [content]="'CART'"
      size="1"
      class="main-header">
    </g-heading>

    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }

    @if (status.status === ObservableStatus.ERROR) {
      <div class="error-container">
        <g-heading
          size="2"
          [content]="'We\'re sorry, we encountered an error'">
        </g-heading>
        <g-error>
          Please refresh the page or contact
          <a href="/aviation-support">Aviation Product Support</a>
          if the issue continues.
        </g-error>
      </div>
    }

    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      @if (userConfig(); as userConfig) {
        @switch (currentState) {
          @case (State.CartDisplay) {
            @if (
              showOemDealerTransferWarning && (userConfig.isOemUser || userConfig.isDealerUser) && !userConfig.isFleet
            ) {
              <g-alert
                [closeable]="false"
                [leftIcon]="true"
                type="warning">
                <p>
                  Please contact <a href="/aviation-support">Aviation Product Support</a> before purchasing databases
                  for an aircraft that you plan to transfer. New database subscriptions will be canceled during the
                  aircraft transfer.
                </p>
              </g-alert>
            }
            @if (totalNumberOfCartItems > 0) {
              <g-flex class="cart-container">
                <div class="cart-items-container">
                  @for (discountCode of discountCodeCart; track trackItem($index, discountCode)) {
                    <fly-cart-item-discount-code
                      [item]="discountCode"
                      (removeDiscountCodeCartItem)="removeDiscountCodeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-item-discount-code>
                  }
                  @for (
                    aircraftBundlesPerAircraft of status.value.categorizedCart.aircraftBundlesCart | keyvalue;
                    track aircraftBundlesPerAircraft
                  ) {
                    <fly-cart-items-avdb-bundles
                      [skuProductsMap]="status.value.skuProductsMap"
                      [renewalSubscriptionsMap]="status.value.renewalSubscriptionsMap"
                      [cartItemMode]="cartItemMode"
                      [avdbBundlesCartItems]="aircraftBundlesPerAircraft.value"
                      [aircraftId]="aircraftBundlesPerAircraft.key"
                      (priceByCartItemId)="addToPricesByCartItemMap($event)"
                      (removeCartItem)="removeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-items-avdb-bundles>
                  }
                  @for (
                    deviceBundlesPerDevice of status.value.categorizedCart.deviceBundlesCart | keyvalue;
                    track deviceBundlesPerDevice
                  ) {
                    <fly-cart-items-avdb-bundles
                      [skuProductsMap]="status.value.skuProductsMap"
                      [renewalSubscriptionsMap]="status.value.renewalSubscriptionsMap"
                      [cartItemMode]="cartItemMode"
                      [avdbBundlesCartItems]="deviceBundlesPerDevice.value"
                      [deviceId]="deviceBundlesPerDevice.key"
                      (priceByCartItemId)="addToPricesByCartItemMap($event)"
                      (removeCartItem)="removeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-items-avdb-bundles>
                  }
                  @for (avdb of status.value.categorizedCart.avdbCart; track avdb.id) {
                    <fly-cart-item-avdb
                      [cartItemMode]="cartItemMode"
                      [item]="avdb"
                      [renewalSubscriptionsMap]="status.value.renewalSubscriptionsMap"
                      [skuProduct]="status.value.skuProductsMap.get(avdb.product.partKey)"
                      (priceByCartItemId)="addToPricesByCartItemMap($event)"
                      (removeCartItem)="removeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-item-avdb>
                  }
                  @for (garminPilotProduct of status.value.categorizedCart.gpCart; track garminPilotProduct.id) {
                    <fly-cart-item-gp
                      [skuProduct]="status.value.skuProductsMap.get(garminPilotProduct.product.partNumber)"
                      [cartItemMode]="cartItemMode"
                      [item]="garminPilotProduct"
                      [renewalSubscriptionsMap]="status.value.renewalSubscriptionsMap"
                      (priceByCartItemId)="addToPricesByCartItemMap($event)"
                      (removeCartItem)="removeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-item-gp>
                  }
                  @for (
                    enablementsPerDevice of status.value.categorizedCart.enablementsCart | keyvalue;
                    track enablementsPerDevice
                  ) {
                    <fly-cart-items-enablements
                      [skuProductsMap]="status.value.skuProductsMap"
                      [cartItemMode]="cartItemMode"
                      [enablementsCartItems]="enablementsPerDevice.value"
                      [deviceId]="enablementsPerDevice.key"
                      (priceByCartItemId)="addToPricesByCartItemMap($event)"
                      (removeCartItem)="removeCartItem($event)"
                      (removingCartItem)="countNumberOfCartItemsBeingRemoved($event)">
                    </fly-cart-items-enablements>
                  }
                </div>

                <div class="cart-summary-wrapper">
                  <fly-cart-summary
                    [foundCartItemsError]="foundCartItemsError()"
                    [subtotal]="subtotal()"
                    [numberOfCartItemsBeingRemoved]="numberOfCartItemsBeingRemoved"
                    [discountCodeCart]="discountCodeCart"
                    [userConfig]="userConfig"
                    [fleetUserErrorMessage]="fleetUserErrorMessage"
                    (addDiscountCodeCartItem)="addDiscountCodeCartItem($event)"
                    (checkout)="startCheckout($event)">
                  </fly-cart-summary>
                </div>
              </g-flex>
            } @else {
              <div class="empty-cart-container">
                <g-heading
                  size="2"
                  [content]="'Your Cart Is Empty'">
                </g-heading>
                <p>To checkout, please add a Database, Subscription, or Enablement to your cart.</p>
              </div>
            }
          }
          @case (State.Checkout) {
            <fly-checkout
              [userConfig]="userConfig"
              [cartId]="status.value.categorizedCart.cartId"
              [billingAccountId]="selectedBillingAccountId ?? undefined"
              (returnToCart)="refreshCart()">
            </fly-checkout>
          }
        }
      }
    }
  </div>
}
