<ng-template #loading>
  @for (_ of avdbBundlesCartItems; track _) {
    <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
  }
  @for (_ of legacySubscriptionExpiringResponses; track _) {
    <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
  }
</ng-template>

<ng-template #error>
  @for (avdbBundle of legacySubscriptionExpiringResponses; track avdbBundle.id) {
    <fly-cart-item-avdb-bundle
      [skuProduct]="skuProductsMap.get(avdbBundle.bundleSKU)"
      [legacySubscriptionExpiringResponse]="avdbBundle"
      [renewalSubscriptionsMap]="renewalSubscriptionsMap"
      [hasError]="hasError"
      [cartItemMode]="cartItemMode">
    </fly-cart-item-avdb-bundle>
  }
  @for (avdbBundleCartItem of avdbBundlesCartItems; track avdbBundleCartItem.id) {
    <fly-cart-item-avdb-bundle
      [skuProduct]="skuProductsMap.get(avdbBundleCartItem.bundle.partKey)"
      [item]="avdbBundleCartItem"
      [renewalSubscriptionsMap]="renewalSubscriptionsMap"
      [hasError]="hasError"
      [cartItemMode]="cartItemMode"
      (removeCartItem)="removeCartItem.emit($event)"
      (removingCartItem)="removingCartItem.emit($event)">
    </fly-cart-item-avdb-bundle>
  }
</ng-template>

@if (avdbPricingResponse$ | flyObservableStatus | async; as status) {
  @if (status.status === ObservableStatus.LOADING) {
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  }
  @if (status.status === ObservableStatus.ERROR) {
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  }
  @if (status.status === ObservableStatus.LOADED && status.value != null) {
    @for (avdbBundle of legacySubscriptionExpiringResponses; track avdbBundle.id) {
      <fly-cart-item-avdb-bundle
        [skuProduct]="skuProductsMap.get(avdbBundle.bundleSKU)"
        [cartItemMode]="cartItemMode"
        [legacySubscriptionExpiringResponse]="avdbBundle"
        [avdbPricingResponse]="status.value"
        [currentCartCategorized]="currentCartCategorized"
        [renewalSubscriptionsMap]="renewalSubscriptionsMap">
      </fly-cart-item-avdb-bundle>
    }
    @for (avdbBundleCartItem of avdbBundlesCartItems; track avdbBundleCartItem.id) {
      <fly-cart-item-avdb-bundle
        [skuProduct]="skuProductsMap.get(avdbBundleCartItem.bundle.partKey)"
        [cartItemMode]="cartItemMode"
        [item]="avdbBundleCartItem"
        [avdbPricingResponse]="status.value"
        [renewalSubscriptionsMap]="renewalSubscriptionsMap"
        (priceByCartItemId)="priceByCartItemId.emit($event)"
        (removeCartItem)="removeCartItem.emit($event)"
        (removingCartItem)="removingCartItem.emit($event)">
      </fly-cart-item-avdb-bundle>
    }
  }
}
