<ng-template #loading>
  <fly-full-page-loader> </fly-full-page-loader>
</ng-template>

<div>
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }

    @if (status.status === ObservableStatus.ERROR) {
      @switch (true) {
        @case (status.statusCode === 404 || status.statusCode === 401) {
          <div class="not-found-error">
            <g-heading
              size="1"
              [content]="'We\'re sorry, we couldn\'t locate your order'">
            </g-heading>
            <g-error>
              Please review the URL and make corrections or contact
              <a routerLink="/aviation-support">Aviation Product Support</a>.
            </g-error>
          </div>
        }
        @default {
          <div class="error">
            <g-heading
              size="1"
              [content]="'We\'re sorry, we encountered an error'">
            </g-heading>
            <g-error>
              Please try again or contact
              <a routerLink="/aviation-support">Aviation Product Support</a>.
            </g-error>
          </div>
        }
      }
    }

    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      <g-heading
        [content]="'ORDER CONFIRMATION'"
        size="1"
        class="header">
      </g-heading>
      <h1 class="details-header">ORDER DETAILS</h1>
      <g-flex class="order-details-container">
        <div class="details">
          <g-flex class="processing-container">
            <div class="order-number-info">
              <span class="order-number"
                >Order Number:
                <strong>
                  {{ status.value.orderInfo.webOrderId }}
                </strong>
              </span>
              <span class="ordered-date">Ordered on: {{ status.value.orderInfo.createdDate | flyFormattedDate }}</span>
            </div>
            <div class="garmin-blue processing-message">
              <p>
                We are currently processing your order.
                <b>Your purchased items may take a few minutes to display.</b>
                Thank you for your patience.
              </p>
            </div>
          </g-flex>
          <div class="details">
            @for (
              aircraftBundlesPerAircraft of status.value.categorizedCart.aircraftBundlesCart | keyvalue;
              track aircraftBundlesPerAircraft
            ) {
              <fly-cart-items-avdb-bundles
                [skuProductsMap]="status.value.skuProductsMap"
                [cartItemMode]="cartItemMode"
                [avdbBundlesCartItems]="aircraftBundlesPerAircraft.value"
                [aircraftId]="aircraftBundlesPerAircraft.key">
              </fly-cart-items-avdb-bundles>
            }
            @for (
              deviceBundlesPerDevice of status.value.categorizedCart.deviceBundlesCart | keyvalue;
              track deviceBundlesPerDevice
            ) {
              <fly-cart-items-avdb-bundles
                [skuProductsMap]="status.value.skuProductsMap"
                [cartItemMode]="cartItemMode"
                [avdbBundlesCartItems]="deviceBundlesPerDevice.value"
                [deviceId]="deviceBundlesPerDevice.key">
              </fly-cart-items-avdb-bundles>
            }
            @for (avdb of status.value.categorizedCart.avdbCart; track avdb.id) {
              <fly-cart-item-avdb
                [skuProduct]="status.value.skuProductsMap.get(avdb.product.partKey)"
                [cartItemMode]="cartItemMode"
                [item]="avdb">
              </fly-cart-item-avdb>
            }
            @for (garminPilotProduct of status.value.categorizedCart.gpCart; track garminPilotProduct.id) {
              <fly-cart-item-gp
                [skuProduct]="status.value.skuProductsMap.get(garminPilotProduct.product.partNumber)"
                [cartItemMode]="cartItemMode"
                [item]="garminPilotProduct">
              </fly-cart-item-gp>
            }
            @for (
              enablementsPerDevice of status.value.categorizedCart.enablementsCart | keyvalue;
              track enablementsPerDevice
            ) {
              <fly-cart-items-enablements
                [skuProductsMap]="status.value.skuProductsMap"
                [cartItemMode]="cartItemMode"
                [enablementsCartItems]="enablementsPerDevice.value"
                [deviceId]="enablementsPerDevice.key">
              </fly-cart-items-enablements>
            }
          </div>
          @if (displayAutoRenewalMessage) {
            <p class="autorenewal"
              >Some of the items in this order may be subscriptions that will autorenew, offer different database
              options, or have a start date in the future. Please visit the
              <a [routerLink]="gpAutoRenewalEnabled ? '/subscriptions' : '/subscriptions/databases'">Subscriptions</a>
              page to manage your settings.
            </p>
          }
        </div>

        <div class="payment">
          <fly-payment-details [orderInfo]="status.value.orderInfo"> </fly-payment-details>
        </div>
      </g-flex>
    }
  }
</div>
