import { Component, computed, DestroyRef, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SkuProduct, SubscriptionResponse, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { UserConfig, UserConfigService } from 'src/app/core/services/config/user-config.service';
import { CartItemMode } from '../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../shared/enums/observable-status-enum';
import { CartItem } from '../../../../../shared/models/cart/cart-item';
import { CategorizedCart } from '../../../../../shared/models/cart/categorized-cart.model';
import { DiscountCodeCartItem } from '../../../../../shared/models/cart/discount-code-cart-item';
import { PilotCartItem } from '../../../../../shared/models/cart/pilot-cart-item';
import { Money } from '../../../../../shared/models/money.model';
import { CartService } from '../../../../../shared/services/cart.service';
import { MoneyService } from '../../../../../shared/services/money.service';
import { SkuService } from '../../../../../shared/services/sku.service';
import { nullAndUndefinedFilter } from '../../../../../shared/utilities/null-and-undefined-filter';
import { SubscriptionsGeneralService } from './../../../../../shared/services/subscriptions-general.service';

enum State {
  CartDisplay,
  Checkout
}

@Component({
  selector: 'fly-cart',
  styleUrl: './cart.components.scss',
  templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit {
  private readonly featuresService = inject(FeaturesService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly gpCartItems: WritableSignal<Map<string, PilotCartItem>> = signal(new Map());
  readonly ObservableStatus = ObservableStatus;
  readonly State = State;

  showOemDealerTransferWarning: boolean = false;
  cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  asyncData$: Observable<{
    skuProductsMap: Map<string, SkuProduct>;
    categorizedCart: CategorizedCart;
    renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
  }>;
  pricesByCartItemId: Map<string, { price?: Money; error?: string }> = new Map();
  foundCartItemsError = signal<boolean>(false);
  subtotal = signal<{ sum: Money; error?: string } | null>(null);
  totalNumberOfCartItems: number;
  numberOfCartItemsBeingRemoved: number = 0;
  discountCodeCart: DiscountCodeCartItem[] = [];
  renewalSubscriptions: Array<SubscriptionResponse<YarmouthSubscription>> = [];
  currentState: State = State.CartDisplay;
  selectedBillingAccountId: string | null = null;
  userConfig: WritableSignal<UserConfig | null> = signal(null);
  readonly fleetUserErrorMessage: Signal<string | null> = computed(() => {
    const isFleetUser = this.userConfig()?.isFleet ?? false;
    const gpCartItems = this.gpCartItems();
    if (isFleetUser && gpCartItems.size > 0) {
      return (
        'Fleet users are not able to purchase GP Subscriptions at this time. ' +
        'Please remove GP subscriptions from your cart to checkout.'
      );
    }
    return null;
  });

  constructor(
    readonly cartService: CartService,
    readonly skuService: SkuService,
    readonly moneyService: MoneyService,
    readonly userConfigService: UserConfigService,
    readonly subscriptionsGeneralService: SubscriptionsGeneralService
  ) {
    userConfigService.getUserConfig().subscribe((userConfig) => {
      this.userConfig.set(userConfig);
    });
  }

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.AR_OEM_DEALER_TRANSFER_WARNING)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((active) => {
        this.showOemDealerTransferWarning = active;
      });

    this.refreshCart();
  }

  refreshCart(): void {
    this.currentState = State.CartDisplay;
    this.subtotal.set(null);

    this.asyncData$ = forkJoin([
      this.cartService.getCurrentCart(),
      this.featuresService.isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL)
    ]).pipe(
      mergeMap(([currentCart, subscriptionsEarlyRenewalEnabled]) => {
        const skusToFetch = [
          ...currentCart.avdbCart.map((item) => item.product.partKey),
          ...currentCart.avdbBundleCart.map((item) => item.bundle.partKey),
          ...currentCart.enablementCart.map((item) => item.product.partKey),
          ...currentCart.gpCart.map((item) => item.product.partNumber)
        ];
        const skuProductsMap$ = this.skuService.skuProductsMap$(skusToFetch);

        let renewalSubscriptionsMap$ = of(new Map<string, YarmouthSubscription>());
        if (subscriptionsEarlyRenewalEnabled) {
          const renewalSubscriptionsToFetch: string[] = Array.from(
            new Set([
              ...currentCart.avdbCart.map((item) => item.existingSubscriptionUuid),
              ...currentCart.avdbBundleCart.map((item) => item.existingSubscriptionUuid),
              ...currentCart.gpCart.map((item) => item.existingSubscriptionUuid)
            ])
          ).filter((subscriptionUuid): subscriptionUuid is string => subscriptionUuid != null);

          renewalSubscriptionsMap$ =
            renewalSubscriptionsToFetch.length > 0
              ? forkJoin(
                  renewalSubscriptionsToFetch.map((subscriptionUuid) =>
                    this.subscriptionsGeneralService.getSubscriptionByUuid(subscriptionUuid).pipe(
                      map((subscription) => {
                        if (subscription.data == null) {
                          this.foundCartItemsError.set(true);
                        }
                        return subscription;
                      }),
                      catchError(() => {
                        this.foundCartItemsError.set(true);
                        return of(null);
                      })
                    )
                  )
                ).pipe(
                  map(
                    (subscriptions) =>
                      new Map(
                        subscriptions
                          .filter(nullAndUndefinedFilter)
                          .filter((subscription) => subscription.data != null)
                          .map((subscription) => [subscription.data.subscriptionUuid, subscription.data])
                      )
                  )
                )
              : of(new Map<string, YarmouthSubscription>());
        }

        return forkJoin([renewalSubscriptionsMap$, skuProductsMap$]).pipe(
          map(([renewalSubscriptionsMap, skuProductsMap]) => {
            const categorizedCart = this.cartService.categorizeCartItems(currentCart);
            return { skuProductsMap, categorizedCart, renewalSubscriptionsMap };
          })
        );
      }),
      tap(
        (result: {
          skuProductsMap: Map<string, SkuProduct>;
          categorizedCart: CategorizedCart;
          renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
        }) => {
          this.discountCodeCart = result.categorizedCart.discountCodeCart;
          this.totalNumberOfCartItems = result.categorizedCart.totalNumberOfItems;
          if (result.categorizedCart.gpCart.length > 0) {
            const cartItems = new Map<string, PilotCartItem>();
            result.categorizedCart.gpCart.forEach((cartItem) => {
              cartItems.set(cartItem.id, cartItem);
            });
            this.gpCartItems.set(cartItems);
          }
        }
      )
    );
  }

  trackItem(_index: number, item: CartItem): string {
    return item.id;
  }

  countNumberOfCartItemsBeingRemoved(beingRemoved: boolean): void {
    this.numberOfCartItemsBeingRemoved += beingRemoved ? 1 : -1;
  }

  removeCartItem(cartItem: CartItem): void {
    this.pricesByCartItemId.delete(cartItem.id);
    this.totalNumberOfCartItems -= 1;

    if (this.gpCartItems().has(cartItem.id)) {
      this.gpCartItems.update((cartItems) => {
        cartItems.delete(cartItem.id);
        return new Map(cartItems);
      });
    }

    this.setSubtotal();
  }

  addToPricesByCartItemMap(priceByCartItem: { cartItemId: string; price: { price?: Money; error?: string } }): void {
    this.pricesByCartItemId.set(priceByCartItem.cartItemId, priceByCartItem.price);
    this.setSubtotal();
  }

  addDiscountCodeCartItem(promoCodeCartItem: DiscountCodeCartItem): void {
    this.discountCodeCart.push(promoCodeCartItem);
    this.totalNumberOfCartItems += 1;
  }

  removeDiscountCodeCartItem(promoCodeCartItem: CartItem): void {
    this.discountCodeCart = this.discountCodeCart.filter((item) => item.id !== promoCodeCartItem.id);
    this.totalNumberOfCartItems -= 1;
    this.setSubtotal();
  }

  startCheckout(billingAccountId: string): void {
    this.selectedBillingAccountId = billingAccountId;
    this.currentState = State.Checkout;
  }

  private setSubtotal(): void {
    if (
      this.totalNumberOfCartItems != null &&
      this.discountCodeCart.length + this.pricesByCartItemId.size === this.totalNumberOfCartItems
    ) {
      const allCartItemsPrices = Array.from(this.pricesByCartItemId.values());
      const foundCartItemsError = allCartItemsPrices.some(
        (priceByCartItemId: { price?: Money; error?: string }) => 'error' in priceByCartItemId
      );
      this.foundCartItemsError.set(this.foundCartItemsError() || foundCartItemsError);

      if (!foundCartItemsError && this.totalNumberOfCartItems > 0) {
        this.subtotal.set(
          this.moneyService.sum(allCartItemsPrices.map((price) => price.price).filter(nullAndUndefinedFilter))
        );
      }
    }
  }
}
