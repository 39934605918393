@if (isLoading) {
  <g-loader type="dark"></g-loader>
}

@if (matchingDatabase != null && !isLoading) {
  @for (series of matchingDatabase.series; track series.id; let isLast = $last) {
    <g-flex class="series-container">
      <span class="series-header">
        <strong>
          {{ series.region.name + (series.region.vendorName ? ' by ' + series.region.vendorName : '') }}
        </strong>
      </span>

      @if (matchingDatabase.series.length === 1) {
        <span class="part-number"> <strong>Part Number:</strong> {{ productPartKey }} </span>
      }

      <g-button
        (click)="
          seriesToExpandDescriptionFor.has(series)
            ? seriesToExpandDescriptionFor.delete(series)
            : seriesToExpandDescriptionFor.add(series)
        "
        [theme]="'light'"
        [size]="'medium'"
        [flat]="true"
        [flatTheme]="'primary'"
        class="btn-margin">
        <g-flex class="coverage-details-container">
          {{ seriesToExpandDescriptionFor.has(series) ? 'Hide Coverage Details' : 'View Coverage Details' }}
          <img
            alt="{{ seriesToExpandDescriptionFor.has(series) ? 'Hide Coverage Details' : 'View Coverage Details' }}"
            [ngClass]="{ 'rotate-arrow': seriesToExpandDescriptionFor.has(series) }"
            class="arrow-icon"
            src="assets/garmin-regalia-icons/arrow-down/icon.svg" />
        </g-flex>
      </g-button>

      @if (seriesToExpandDescriptionFor.has(series)) {
        <div [innerHTML]="series.region.coverageDescriptionHTML"></div>

        @if (series.region.coverageMapFilename && series.region.coverageMapFilename.length > 0) {
          <ng-container class="map-container">
            <img
              alt="Coverage Map"
              class="map-img"
              src="{{ coverageMapUrl + series.region.coverageMapFilename }}" />
          </ng-container>
        }
      }
    </g-flex>

    @if (!isLast) {
      <h3>- AND -</h3>
    }
  }
}
