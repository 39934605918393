import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { UserConfig } from '../../../../../core/services/config/user-config.service';

enum State {
  AircraftPromotion,
  CartValidation,
  CheckoutRedirect
}

@Component({
  selector: 'fly-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @Input({ required: true }) userConfig: UserConfig;
  @Input({ required: true }) cartId: number;
  @Input() billingAccountId?: string;
  @Output() readonly returnToCart: EventEmitter<string> = new EventEmitter();
  errorOccurred: boolean = false;
  currentState: State;
  readonly State = State;
  private readonly featuresService = inject(FeaturesService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.CHECKOUT_MIGRATION)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((active) => {
        if (this.userConfig.isFleet || !active) {
          this.currentState = State.CheckoutRedirect;
        } else {
          this.currentState = State.AircraftPromotion;
        }
      });
  }

  promotionComplete(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.AR_CHECKOUT_VALIDATION)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((active) => {
        if (active) {
          this.currentState = State.CartValidation;
        } else {
          this.currentState = State.CheckoutRedirect;
        }
      });
  }
}
