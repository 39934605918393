import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AvdbPricingBundleType,
  CartItemType,
  SkuProduct,
  YarmouthSubscription
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CartItemMode } from '../../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../../shared/enums/observable-status-enum';
import { CartItem } from '../../../../../../shared/models/cart/cart-item';
import { Cart } from '../../../../../../shared/models/cart/cart.model';
import { PilotCartItem } from '../../../../../../shared/models/cart/pilot-cart-item';
import { GarminPilotProduct } from '../../../../../../shared/models/garmin-pilot/gp-product.model';
import { Money } from '../../../../../../shared/models/money.model';
import { CartService } from '../../../../../../shared/services/cart.service';
import { DatesService } from '../../../../../../shared/services/dates.service';
import { GarminPilotCatalogService } from '../../../../../../shared/services/garmin-pilot-catalog.service';
import { LinksService } from '../../../../../../shared/services/links.service';
import { CartItemUtilityService } from './../../../../../../shared/services/cart-item-utility.service';

@Component({
  selector: 'fly-cart-item-gp',
  templateUrl: './cart-item-gp.component.html',
  styleUrl: './cart-item-gp.component.scss'
})
export class CartItemGpComponent implements OnInit {
  @Input() garminPilotProducts: GarminPilotProduct[];
  @Input() cartItemMode: CartItemMode = CartItemMode.CHECKOUT;
  @Input() skuProduct?: SkuProduct;
  @Input() item: PilotCartItem;
  @Input() productDetails: GarminPilotProduct;
  @Input() renewalSubscriptionsMap: Map<string, YarmouthSubscription>;
  @Input() hasError: boolean = false;
  @Output() readonly priceByCartItemId = new EventEmitter<{
    cartItemId: string;
    price: { price?: Money; error?: string };
  }>();
  @Output() readonly removeCartItem: EventEmitter<CartItem> = new EventEmitter();
  @Output() readonly removingCartItem: EventEmitter<boolean> = new EventEmitter();
  readonly ObservableStatus = ObservableStatus;
  cartItemType: CartItemType = CartItemType.GARMIN_PILOT;
  removeCartItem$: Observable<Cart>;
  garminPilotProducts$: Observable<GarminPilotProduct[]>;
  asyncData$: Observable<AsyncData>;
  additionalInfo: string | null;
  expandCollapsedSubscriptionDetails: boolean = false;
  protected readonly AvdbPricingBundleType = AvdbPricingBundleType;
  newExpiration: Date | null = null;
  protected readonly CartItemMode = CartItemMode;
  subscriptionsEarlyRenewalEnabled = false;
  futureStartEnabled = false;

  private readonly datesService = inject(DatesService);
  private readonly garminPilotCatalogService = inject(GarminPilotCatalogService);
  private readonly cartService = inject(CartService);
  private readonly linksService = inject(LinksService);
  private readonly cartItemUtilityService = inject(CartItemUtilityService);
  private readonly featuresService = inject(FeaturesService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.skuProduct == null) {
      this.hasError = true;
      return;
    }

    this.additionalInfo =
      this.skuProduct.duration === '1 Year' && this.item.existingSubscriptionUuid == null && this.newExpiration == null
        ? `Will automatically renew on ${this.datesService.getCurrentDatePlusOneYear()} (estimate)`
        : null;

    this.removeCartItem$ = this.item == null ? EMPTY : this.cartService.removeGpItemFromCurrentCart(this.item.id);

    this.garminPilotProducts$ = ((): Observable<GarminPilotProduct[]> => {
      if (this.item == null || this.hasError) {
        return EMPTY;
      } else if (this.cartItemMode !== CartItemMode.CONFIRMATION || this.garminPilotProducts == null) {
        return this.garminPilotCatalogService.getProductByPartNumber(
          this.skuProduct.sku ?? this.item?.product.partNumber
        );
      } else {
        return of(this.garminPilotProducts);
      }
    })();

    this.asyncData$ = this.garminPilotProducts$.pipe(
      map((garminPilotProducts) => {
        const garminPilotProduct = this.findGarminPilotProduct(garminPilotProducts);
        const price = this.findPrice(garminPilotProduct);
        return {
          garminPilotProduct,
          price
        };
      }),
      tap((asyncData: AsyncData) => {
        this.priceByCartItemId.emit({ cartItemId: this.item.id, price: { price: asyncData.price } });
      }),
      catchError((error: any) => {
        this.hasError = true;
        const priceWithError: Money = {
          value: null,
          currencyCode: null,
          currencySymbol: null,
          fractionalDigits: null,
          formattedValue: null
        };
        this.priceByCartItemId.emit({
          cartItemId: this.item.id,
          price: { price: priceWithError, error: error.message }
        });
        return throwError(() => error);
      })
    );

    if (this.item.existingSubscriptionUuid != null) {
      this.featuresService
        .isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((enabled) => {
          this.subscriptionsEarlyRenewalEnabled = enabled;
          if (enabled) {
            if (this.skuProduct != null && this.cartItemMode === CartItemMode.CHECKOUT) {
              try {
                this.newExpiration = this.cartItemUtilityService.calculateNewRenewalDate(
                  this.item,
                  this.renewalSubscriptionsMap,
                  this.skuProduct
                );
              } catch (error) {
                this.hasError = true;
                throw error;
              }
            }
          } else {
            this.hasError = true;
            throw new Error();
          }
        });
    }

    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.ENABLE_FUTURE_START_DATE)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.futureStartEnabled = enabled));
  }

  performAfterPurchaseAction = (): void => {
    window.location.href = this.linksService.getFlyLegacyGarminPilotSupscriptionsUrl();
  };

  private findGarminPilotProduct(garminPilotProducts: GarminPilotProduct[]): GarminPilotProduct | undefined {
    if (this.cartItemMode === CartItemMode.CONFIRMATION) {
      return undefined;
    }
    return garminPilotProducts.find(
      (garminPilotProduct) => (garminPilotProduct.partNumber = this.skuProduct?.sku ?? this.item?.product.partNumber)
    );
  }

  private findPrice(garminPilotProduct?: GarminPilotProduct): Money | undefined {
    switch (this.cartItemMode) {
      case CartItemMode.CONFIRMATION:
        return this.item.orderPrice;
      case CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION:
        return garminPilotProduct?.price;
      case CartItemMode.CHECKOUT:
        return garminPilotProduct?.price;
      default:
        return garminPilotProduct?.price;
    }
  }
}

interface AsyncData {
  garminPilotProduct?: GarminPilotProduct;
  price?: Money;
}
