<div class="payment-container">
  <h2 class="payment-header">PAYMENT DETAILS</h2>
  <table aria-describedby="Payment Details">
    <caption class="payment-caption">Payment Details</caption>
    <tbody>
      <tr>
        <th class="payment-details-item-label"> Item Subtotal </th>
        <td class="payment-details-item-value">
          <g-price
            [originalPrice]="orderInfo.orderSubTotalMoney | flyMoney"
            class="price-justified-end">
          </g-price>
        </td>
      </tr>
      <tr>
        <th class="payment-details-item-label payment-details-tax-label"> Tax </th>
        <td class="payment-details-item-value payment-details-tax-value">
          <g-price
            [originalPrice]="orderInfo.taxAmountMoney | flyMoney"
            class="price-justified-end">
          </g-price>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="payment-details-total-row">
        <th class="order-total-text"> Order Total </th>
        <td class="order-total-money">
          <g-price
            [originalPrice]="orderInfo.orderTotalMoney | flyMoney"
            class="price-justified-end">
          </g-price>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
