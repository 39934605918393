import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UserConfig } from '../../../../../core/services/config/user-config.service';
import { CartValidationService } from '../../../../../shared/services/cart-validation.service';

enum State {
  Validating,
  Error,
  Completed
}

@Component({
  selector: 'fly-cart-validation',
  templateUrl: './cart-validation.component.html'
})
export class CartValidationComponent implements OnInit {
  @Input({ required: true }) userConfig: UserConfig;
  @Input() billingAccountId?: string;
  @Output() readonly validationCompleted = new EventEmitter<void>();
  @Output() readonly errorOccurred = new EventEmitter<void>();
  @Output() readonly returnToCart = new EventEmitter<void>();

  currentState: State = State.Validating;
  errorMsg: string = 'An error occurred, and we were unable to validate your cart.';
  readonly State = State;
  private readonly cartValidationService = inject(CartValidationService);

  ngOnInit(): void {
    this.cartValidationService.validateCart(this.userConfig.loggedInUserCustomerGuid, this.billingAccountId).subscribe({
      next: (response) => {
        if (response.success) {
          this.validationCompleted.emit();
          return;
        }

        if (response.errors != null) {
          this.returnToCart.emit();
          this.currentState = State.Completed;
        }
      },
      error: () => {
        this.currentState = State.Error;
        this.errorOccurred.emit();
      }
    });
  }
}
