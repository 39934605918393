import { Injectable, inject } from '@angular/core';
import {
  AvdbDeviceIdType,
  AvdbPricingBundleType,
  SubscriptionResponse
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { Observable, ReplaySubject, catchError, of, map, switchMap, throwError, mergeMap } from 'rxjs';
import { AvdbBundleCartItem } from '../models/cart/avdb-bundle-cart-item';
import { CartItemValidation } from '../models/subscriptions/cart-item-validation.model';
import { CartValidationRequest } from '../models/subscriptions/cart-validation-request.model';
import { GarminPilotCartItem } from '../models/subscriptions/garmin-pilot-cart-item.model';
import { YarmouthAvdbCartItem } from '../models/subscriptions/yarmouth-avdb-cart-item.model';
import { CartService } from './cart.service';
import { YarmouthCartsService } from './yarmouth-carts.service';

@Injectable({
  providedIn: 'root'
})
export class CartValidationService {
  private readonly cartService = inject(CartService);
  private readonly yarmouthCartsService = inject(YarmouthCartsService);
  private readonly featuresService = inject(FeaturesService);
  private readonly cartValidations: ReplaySubject<CartItemValidation[]> = new ReplaySubject(1);

  validateCart(customerGuid: string, billingAccountId?: string): Observable<SubscriptionResponse> {
    // Let cart items know to reset error message
    this.cartValidations.next([]);

    return this.featuresService.isFeatureActive(FeatureFlyGarmin.GP_AUTO_RENEWALS).pipe(
      mergeMap((gpAutorenewalsActive) => {
        return this.cartService.getCurrentCart().pipe(
          switchMap((cart) => {
            let avdbCartItems = cart.avdbCart.map((avdbCartItem) => {
              const item: YarmouthAvdbCartItem = {
                cartItemId: parseInt(avdbCartItem.id),
                deviceId: avdbCartItem.device.displaySerial,
                deviceIdType:
                  avdbCartItem.device?.aircraft == null
                    ? AvdbDeviceIdType.PORTABLE_DEVICE_SERIAL_NUMBER
                    : AvdbDeviceIdType.SYSTEM_ID,
                aircraftUuid: avdbCartItem.device.aircraft?.masterAircraftUuid,
                sku: avdbCartItem.product.partKey,
                existingSubscriptionUuid: avdbCartItem.existingSubscriptionUuid
              };

              return item;
            });

            avdbCartItems = avdbCartItems.concat(
              cart.avdbBundleCart.map((avdbBundleCartItem) => {
                const item: YarmouthAvdbCartItem = {
                  cartItemId: parseInt(avdbBundleCartItem.id),
                  deviceId: avdbBundleCartItem.device?.displaySerial,
                  deviceIdType:
                    avdbBundleCartItem.device != null ? this.getAvdbBundleDeviceIdType(avdbBundleCartItem) : undefined,
                  aircraftUuid: this.getMasterAircraftUuid(avdbBundleCartItem),
                  sku: avdbBundleCartItem.bundle.partKey,
                  existingSubscriptionUuid: avdbBundleCartItem.existingSubscriptionUuid
                };

                return item;
              })
            );

            const garminPilotCartItems = gpAutorenewalsActive
              ? cart.gpCart.map((gpCartItem) => {
                  const item: GarminPilotCartItem = {
                    cartItemId: parseInt(gpCartItem.id),
                    sku: gpCartItem.product.partNumber,
                    existingSubscriptionUuid: gpCartItem.existingSubscriptionUuid
                  };

                  return item;
                })
              : [];

            //create the cart validation request
            const cartValidationRequest: CartValidationRequest = {
              billingAccountId,
              customerGuid,
              avdbCartItems,
              garminPilotCartItems
            };

            return this.yarmouthCartsService.validateCart(cartValidationRequest).pipe(
              switchMap((response) => {
                const validation: CartItemValidation[] = response.success
                  ? []
                  : response.errors.map((error) => {
                      return {
                        completed: true,
                        error
                      };
                    });

                this.cartValidations.next(validation);
                return of(response);
              }),
              catchError((error) => {
                this.cartValidations.next([
                  {
                    completed: false,
                    error: null
                  }
                ]);
                return throwError(() => error);
              })
            );
          })
        );
      })
    );
  }

  getCartItemError(cartItemId: string): Observable<CartItemValidation | null> {
    return this.cartValidations.asObservable().pipe(
      map((validations) => {
        return (
          validations.find(
            (itemValidation) => !itemValidation.completed || itemValidation.error?.property === cartItemId
          ) ?? null
        );
      })
    );
  }

  checkForCartError(): Observable<boolean> {
    return this.cartValidations.pipe(
      map((validations) => {
        return (
          (validations.length === 1 && !validations[0].completed && validations[0].error == null) ||
          validations.some((validation) => validation.completed && validation.error != null)
        );
      })
    );
  }

  private getAvdbBundleDeviceIdType(avdbBundleCartItem: AvdbBundleCartItem): number {
    const bundleType = avdbBundleCartItem.bundle.bundleType;

    if (bundleType == AvdbPricingBundleType.PILOTPAK || bundleType === AvdbPricingBundleType.ONEPAK) {
      return AvdbDeviceIdType.SYSTEM_ID;
    }

    return avdbBundleCartItem.device?.aircraft == null
      ? AvdbDeviceIdType.PORTABLE_DEVICE_SERIAL_NUMBER
      : AvdbDeviceIdType.SYSTEM_ID;
  }

  private getMasterAircraftUuid(avdbBundleCartItem: AvdbBundleCartItem): string | undefined {
    if (avdbBundleCartItem.aircraft != null) {
      return avdbBundleCartItem.aircraft.masterAircraftUuid;
    }
    if (avdbBundleCartItem.device?.aircraft != null) {
      return avdbBundleCartItem.device.aircraft.masterAircraftUuid;
    }
    return undefined;
  }
}
