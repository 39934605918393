<ng-template #loading>
  <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
</ng-template>

<ng-template #error>
  <fly-cart-item
    [cartItem]="item"
    [legacySubscriptionExpiringResponse]="legacySubscriptionExpiringResponse"
    [cartItemType]="cartItemType"
    [cartItemMode]="cartItemMode"
    [hasError]="hasError"
    [removeCartItem$]="removeCartItem$"
    (removeCartItemEvent)="removeCartItem.emit($event)"
    (removingCartItem)="removingCartItem.emit($event)">
    <div header>
      <h2>
        {{ skuProduct?.display ?? item.product.partKey }}
      </h2>
    </div>
  </fly-cart-item>
</ng-template>

@if (!hasError && skuProduct != null) {
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
    @if (status.status === ObservableStatus.ERROR) {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      <fly-cart-item
        [presentInCurrentCart]="presentInCurrentCart"
        [skuProduct]="skuProduct"
        [price]="status.value.price"
        [cartItem]="item"
        [legacySubscriptionExpiringResponse]="legacySubscriptionExpiringResponse"
        [cartItemType]="cartItemType"
        [cartItemMode]="cartItemMode"
        [removeCartItem$]="removeCartItem$"
        [hasCollapsedDetails]="
          status.value.avdbPricingProductType != null && status.value.avdbPricingProductType.products != null
        "
        [kindOfCollapsedDetails]="'Coverage'"
        (expandCollapsedDetailsEvent)="expandCollapsedCoverageDetails = $event"
        (removeCartItemEvent)="removeCartItem.emit($event)"
        (removingCartItem)="removingCartItem.emit($event)">
        <div header>
          <h2 class="cart-item-avdb-header">
            {{ skuProduct.display }}
          </h2>
          <h3 class="cart-item-avdb-subheader">{{ skuProduct.avdbType }}</h3>
        </div>
        <div body>
          @if (containsMultipleDatabases) {
            <p>Contains Multiple Databases</p>
          }
          <p>
            <strong>Covered Device: </strong>
            <a href="{{ flyLegacyDeviceDetailsUrl }}">
              {{
                legacySubscriptionExpiringResponse
                  ? legacySubscriptionExpiringResponse.deviceModelName +
                    ' (' +
                    legacySubscriptionExpiringResponse.deviceId +
                    ')'
                  : item.device.name + ' (' + item.device.displaySerial + ')'
              }}
            </a>
          </p>
          <p>
            <strong>Duration: </strong>
            {{ duration }}
          </p>
          @if (item.existingSubscriptionUuid == null && cartItemMode === CartItemMode.CHECKOUT && futureStartEnabled) {
            <p>
              <strong>Start Date: </strong>
              @if (item.futureStartDate == null) {
                Today
              } @else {
                {{ item.futureStartDate | flyFormattedDate }}
              }
              <button
                class="link-styled-button"
                (click)="changeStartDateModal.open()">
                Change
              </button>
            </p>
          }
          <p> <strong>Part Number:</strong> {{ skuProduct.sku }} </p>
          @if (item.existingSubscriptionUuid != null && subscriptionsEarlyRenewalEnabled) {
            @if (cartItemMode === CartItemMode.CHECKOUT && newExpiration != null) {
              <p> This purchase will extend your existing subscription to {{ newExpiration | flyFormattedDate }}. </p>
            } @else if (cartItemMode === CartItemMode.CONFIRMATION) {
              <br />
              <p
                >Your existing subscription has been extended by <strong>{{ duration }}</strong
                >.</p
              >
              <p>This may take a few minutes to be displayed.</p>
            }
          }
          @if (cartItemMode === CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION) {
            <p class="cart-item-avdb-expiration-text">
              Current coverage will expire on
              {{ legacySubscriptionExpiringResponse.expireDateTime | flyFormattedDate }}
            </p>
          }
        </div>

        @if (status.value.avdbPricingProductType != null && status.value.avdbPricingProductType.products != null) {
          <div collapsedDetails>
            @if (expandCollapsedCoverageDetails) {
              <fly-coverage-details-avdb
                [containsMultipleDatabases]="containsMultipleDatabases"
                [avdbPricingProductType]="status.value.avdbPricingProductType"
                [avdbPricingProducts]="status.value.avdbPricingProductType.products">
              </fly-coverage-details-avdb>
            }
          </div>
        }
      </fly-cart-item>
    }
  }
} @else {
  <ng-container [ngTemplateOutlet]="error"></ng-container>
}

<fly-cart-item-change-start-date-modal
  #changeStartDateModal
  [item]="item"
  [itemType]="'avdb'"></fly-cart-item-change-start-date-modal>
