<fly-modal
  #modal
  header="Change Start Date"
  [buttons]="buttons">
  @if (currentState !== State.Closed) {
    <div slot="body">
      @if (currentState !== State.UpdatingStartDate) {
        <ng-container>
          <div class="first-content">
            <p>
              When checking out, if you change the start date below, you will be charged immediately upon checkout and
              your subscription will begin on the selected date up to 90 days in the future.
            </p>
          </div>
          <div class="second-content">
            <p>
              Please verify the date below is when you want to start your subscription as you will not be able to change
              it after checking out.
            </p>
          </div>
          <p><strong>Start Date</strong></p>
          <div class="radio-buttons">
            <g-radio label="Today">
              <input
                [name]="radioName"
                type="radio"
                value="1"
                id="todayRadio"
                (change)="onSelectionChange()"
                [formControl]="selectedDateOption" />
            </g-radio>
            <g-radio label="Select a different date:">
              <input
                [name]="radioName"
                type="radio"
                value="2"
                id="otherDateRadio"
                (change)="onSelectionChange()"
                [formControl]="selectedDateOption" />
            </g-radio>
          </div>
          <div class="date-picker-wrapper table-form-control">
            <g-input
              label="Date Input"
              label-for="startDateInput">
              <input
                type="date"
                id="startDateInput"
                [formControl]="selectedDate"
                [min]="minDate"
                [max]="maxDate" />
            </g-input>
          </div>
          @if (currentState === State.ErrorUpdatingStartDate) {
            <div>
              <g-error
                >An error occurred while updating the start date for this item. Please refresh the page and try again,
                or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue
                continues.</g-error
              >
            </div>
          }
        </ng-container>
      } @else {
        <g-loader type="dark"> </g-loader>
        Updating Start Date...
      }
    </div>
  }
</fly-modal>
