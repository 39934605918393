@if (!containsMultipleDatabases) {
  <fly-coverage-details-avdb-single-series [avdbPricingProducts]="avdbPricingProductType.products">
  </fly-coverage-details-avdb-single-series>
}

@if (containsMultipleDatabases) {
  <fly-coverage-details-avdb-multiple-series
    [avdbPricingProductType]="avdbPricingProductType"
    [avdbPricingProducts]="avdbPricingProductType.products">
  </fly-coverage-details-avdb-multiple-series>
}
