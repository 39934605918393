import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../../environments/environment';
import { UserConfig } from '../../../../../core/services/config/user-config.service';
import { CartService } from '../../../../../shared/services/cart.service';

enum State {
  GeneratingUrl,
  InvalidPromoCodeError,
  PurchasingDisabledError,
  GenericError
}

@Component({
  selector: 'fly-checkout-redirect',
  templateUrl: './checkout-redirect.component.html',
  styleUrls: ['./checkout-redirect.component.scss']
})
export class CheckoutRedirectComponent implements OnInit {
  @Input({ required: true }) userConfig: UserConfig;
  @Input({ required: true }) cartId: number;
  @Input() billingAccountId?: string;
  @Output() readonly errorOccurred = new EventEmitter<void>();
  currentState: State = State.GeneratingUrl;
  readonly State = State;
  private readonly cartService = inject(CartService);
  private readonly domSanitizer = inject(DomSanitizer);

  ngOnInit(): void {
    if (this.userConfig.isFleet) {
      window.location.href = encodeURI(
        `${environment.flyLegacyUrl}/purchasing/checkout/fleet/?cartID=${encodeURIComponent(this.cartId)}`
      );
    } else {
      this.cartService.checkoutCart(this.cartId, this.billingAccountId).subscribe({
        next: (response) => {
          if (response.wlcUrl != null) {
            const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, response.wlcUrl);

            if (sanitizedUrl != null) {
              window.location.href = encodeURI(sanitizedUrl);
            } else {
              this.currentState = State.GenericError;
              this.errorOccurred.emit();
            }
          } else {
            this.currentState =
              response.errors?.invalidPromo === true ? State.InvalidPromoCodeError : State.GenericError;
            this.errorOccurred.emit();
          }
        },
        error: (response) => {
          this.currentState =
            response?.status === HttpStatusCode.Forbidden ? State.PurchasingDisabledError : State.GenericError;
          this.errorOccurred.emit();
        }
      });
    }
  }
}
