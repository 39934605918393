import { Component, Input, OnInit } from '@angular/core';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { AvdbPricingDevice } from 'src/app/shared/models/avdb-pricing/avdb-pricing-device.model';
import { AvdbPricingProductSeries } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product-series.model';
import { AvdbPricingProduct } from 'src/app/shared/models/avdb-pricing/avdb-pricing-product.model';

@Component({
  selector: 'fly-coverage-details-avdb-bundle-info',
  templateUrl: './coverage-details-avdb-bundle-info.component.html',
  styleUrls: ['./coverage-details-avdb-bundle-info.component.scss']
})
export class CoverageDetailsAvdbBundleInfoComponent implements OnInit {
  @Input() productPartKey: string;
  @Input() avdbTypeId: number;
  @Input() device: AvdbPricingDevice;

  matchingDatabase: AvdbPricingProduct | null = null;
  coverageMapUrl: string;
  isLoading: boolean = true;
  seriesToExpandDescriptionFor: Set<AvdbPricingProductSeries> = new Set<AvdbPricingProductSeries>();

  constructor(readonly siteConfigService: SiteConfigService) {}

  ngOnInit(): void {
    this.matchingDatabase =
      this.device.avdbProductTypes
        .find((type) => type.id === this.avdbTypeId)
        ?.products.find((product) => product.partKey === this.productPartKey) ?? null;

    this.siteConfigService.getSiteConfig().subscribe((config) => {
      this.coverageMapUrl = `${config.cdnUrl}/img/avdb_type_regions/`;
      this.isLoading = false;
    });
  }
}
