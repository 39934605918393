import { Component, Input } from '@angular/core';
import { AvdbPricingProductType } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-product-type.model';
import { AvdbPricingProduct } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-product.model';

@Component({
  selector: 'fly-coverage-details-avdb',
  templateUrl: './coverage-details-avdb.component.html'
})
export class CoverageDetailsAvdbComponent {
  @Input({ required: true }) containsMultipleDatabases: boolean;
  @Input({ required: true }) avdbPricingProductType: AvdbPricingProductType;
  @Input({ required: true }) avdbPricingProducts: AvdbPricingProduct[];
}
