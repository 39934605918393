@if (isLoading) {
  <g-loader type="dark"></g-loader>
}

@if (!isLoading) {
  <g-flex class="product-list-container">
    <strong>Only one of the below may be installed at a time.</strong>

    @for (series of avdbPricingProducts[0].series; track series.id; let isLast = $last) {
      <h3 class="region-name">
        {{ series.region.name + (series.region.vendorName != null ? ' by ' + series.region.vendorName : '') }}
      </h3>

      <g-button
        (click)="
          seriesToExpandDescriptionFor.has(series)
            ? seriesToExpandDescriptionFor.delete(series)
            : seriesToExpandDescriptionFor.add(series)
        "
        [theme]="'light'"
        [size]="'medium'"
        [flat]="true"
        [flatTheme]="'primary'"
        class="toggle-description-btn">
        <g-flex class="coverage-details-container">
          {{ seriesToExpandDescriptionFor.has(series) ? 'Hide Coverage Details' : 'View Coverage Details' }}
          <img
            alt="{{ seriesToExpandDescriptionFor.has(series) ? 'Hide Coverage Details' : 'View Coverage Details' }}"
            [ngClass]="{ 'arrow-rotate': seriesToExpandDescriptionFor.has(series) }"
            class="arrow-icon"
            src="assets/garmin-regalia-icons/arrow-down/icon.svg" />
        </g-flex>
      </g-button>

      @if (seriesToExpandDescriptionFor.has(series)) {
        <div [innerHTML]="series.region.coverageDescriptionHTML"></div>

        @if (series.region.coverageMapFilename != null && series.region.coverageMapFilename.length > 0) {
          <ng-container>
            <img
              alt="Coverage Map"
              class="map-img"
              src="{{ coverageMapUrl + series.region.coverageMapFilename }}" />
          </ng-container>
        }
      }

      @if (!isLast) {
        <h3>- AND -</h3>
      }
    }
  </g-flex>
}
