import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComponentsGlobalWebModule } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import {
  FLY_GARMIN_APP_NAVIGATION_CONFIG_TOKEN,
  FlyGarminAppNavigationConfig,
  FlyGarminAppNavigationModule
} from '@garmin-avcloud/flyGarminAppNavigation';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotAuthorizedComponent } from './core/components/layouts/not-authorized.component';
import { CoreModule } from './core/core.module';
import { CustomTitleStrategyService } from './core/services/custom-title-strategy/custom-title-strategy.service';
import { PurchasingModule } from './features/purchasing/purchasing.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, NotAuthorizedComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    SharedModule,
    FlyGarminAppNavigationModule,
    ComponentsGlobalWebModule,
    FontAwesomeModule,
    PurchasingModule
  ],
  providers: [
    {
      provide: FLY_GARMIN_APP_NAVIGATION_CONFIG_TOKEN,
      useValue: {
        flyLegacyUrl: `${environment.flyLegacyUrl}/`,
        fgUrl: environment.fgUrl,
        environment: environment.name === 'LOCAL' ? 'TEST' : environment.name,
        ssoClientId: 'FLY_GARMIN_APP'
      } as FlyGarminAppNavigationConfig
    },
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategyService
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
