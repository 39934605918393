import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SubscriptionResponse } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CartValidationRequest } from '../models/subscriptions/cart-validation-request.model';

@Injectable({
  providedIn: 'root'
})
export class YarmouthCartsService {
  private readonly baseUrl = `${environment.flyLegacyUrl}/api/v2/carts`;
  private readonly http = inject(HttpClient);

  validateCart(payload: CartValidationRequest): Observable<SubscriptionResponse> {
    return this.http.post<SubscriptionResponse>(`${this.baseUrl}/validate`, payload);
  }
}
