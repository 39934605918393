import { inject, Injectable } from '@angular/core';
import { SkuProduct, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { CartItem } from '../models/cart/cart-item';
import { DatesService } from './dates.service';

@Injectable({
  providedIn: 'root'
})
export class CartItemUtilityService {
  private readonly datesService = inject(DatesService);

  calculateNewRenewalDate(
    item: CartItem,
    renewalSubscriptionsMap: Map<string, YarmouthSubscription>,
    skuProduct: SkuProduct
  ): Date | null {
    if (item.existingSubscriptionUuid != null) {
      const renewingSubscription = renewalSubscriptionsMap.get(item.existingSubscriptionUuid);
      if (
        renewingSubscription != null &&
        (renewingSubscription.endDate != null || renewingSubscription.nextBillDate != null) &&
        skuProduct != null
      ) {
        const expirationDate = this.datesService.parseDate(
          renewingSubscription.endDate ?? renewingSubscription.nextBillDate
        );
        if (expirationDate != null) {
          const splitDuration: string[] = skuProduct.duration.split(' ');
          const durationValue = Number(splitDuration[0]);
          const durationUnit = splitDuration[1].toUpperCase();
          let durationUnitInDays = 1;
          if (durationUnit.includes('MONTH')) {
            durationUnitInDays = 30;
          } else if (durationUnit.includes('YEAR')) {
            durationUnitInDays = 365;
          }
          const durationInDays = durationValue * durationUnitInDays;

          expirationDate.setDate(expirationDate.getDate() + durationInDays);

          return expirationDate;
        }
      } else {
        throw new Error('An error occurred while loading the subscription to be renewed');
      }
    }
    return null;
  }
}
