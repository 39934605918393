@switch (true) {
  @case (bundleType === AvdbPricingBundleType.PILOTPAK || bundleType === AvdbPricingBundleType.ONEPAK) {
    @for (deviceModel of qualifyingDeviceModelsOnAircraft; track deviceModel.modelID) {
      <fly-coverage-details-avdb-bundle-device
        [bundleType]="bundleType"
        [avdbPricing]="avdbPricingResponse"
        [deviceModel]="deviceModel">
      </fly-coverage-details-avdb-bundle-device>
    }
  }
  @case (bundleType === AvdbPricingBundleType.DEVICE) {
    <fly-coverage-details-avdb-bundle-device
      [bundleType]="bundleType"
      [avdbPricing]="avdbPricingResponse"
      [deviceBundle]="deviceBundle">
    </fly-coverage-details-avdb-bundle-device>
  }
}
