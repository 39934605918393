@if (isLoading) {
  <g-loader type="dark"></g-loader>
}

@if (!isLoading) {
  <g-flex class="tw-flex-col">
    <div [innerHTML]="avdbPricingProducts[0].series[0].region.coverageDescriptionHTML"></div>
    @if (coverageMapUrl != null) {
      <img
        alt="Coverage Map"
        class="tw-block"
        src="{{ coverageMapUrl }}" />
    }
  </g-flex>
}
