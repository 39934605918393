<ng-template #header>
  <h5 class="tw-m-0 tw-mb-1 tw-pt-2.5">
    {{ cartItemTypeDisplayName[cartItemType] }}
  </h5>

  @switch (true) {
    @case (headerText == null) {
      <ng-content select="[header]"></ng-content>
    }
    @case (headerText != null) {
      <div>
        <h2 class="tw-m-0">
          {{ headerText }}
        </h2>
      </div>
    }
  }
</ng-template>

<ng-template #body>
  <div class="tw-my-3 standard:tw-mb-0">
    <ng-content select="[body]"></ng-content>
  </div>
</ng-template>

<ng-template #collapsedDetails>
  <g-button
    (click)="expandOrCollapseDetails()"
    [theme]="'light'"
    [size]="'medium'"
    [flat]="true"
    [flatTheme]="'primary'"
    class="tw-m-0">
    <g-flex class="tw-items-center tw-text-left tw-gap-2">
      {{
        expandCollapsedDetails
          ? 'Hide ' + kindOfCollapsedDetails + ' Details'
          : 'View ' + kindOfCollapsedDetails + ' Details'
      }}
      <img
        alt="{{
          expandCollapsedDetails
            ? 'Hide ' + kindOfCollapsedDetails + ' Details'
            : 'View ' + kindOfCollapsedDetails + ' Details'
        }}"
        [ngClass]="{ 'tw-rotate-180': expandCollapsedDetails }"
        class="tw-w-3 tw-h-3"
        src="assets/garmin-regalia-icons/arrow-down/icon.svg" />
    </g-flex>
  </g-button>
  <ng-content select="[collapsedDetails]"></ng-content>
</ng-template>

<ng-template #removeCartItemFailedMessage>
  @if (removeFailed) {
    <g-error class="tw-pb-3">
      An error occurred while removing this item. Please try again, or contact
      <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
    </g-error>
  }
</ng-template>

<ng-template #removeCartItemButton>
  @if (removing) {
    <g-loader
      class="tw-w-12"
      type="dark">
    </g-loader>
  }
  @if (!removing) {
    <button
      (click)="removeCartItem()"
      class="tw-w-5 tw-h-5 tw-mr-2.5">
      <img
        alt="remove"
        src="../../../../../../assets/garmin-regalia-icons/cancel/icon.svg" />
    </button>
  }
</ng-template>

@switch (currentState) {
  @case (State.Loading) {
    <g-flex class="tw-flex-col tw-my-4">
      <g-flex class="cart-item tw-flex-col">
        <g-flex class="tw-flex-row tw-px-4 tw-my-3 tw-justify-between">
          <g-flex class="tw-justify-between tw-flex-col tw-flex-auto">
            <div class="tw-mb-1 tw-h-12 tw-w-full standard:tw-w-96">
              <g-skeleton> </g-skeleton>
            </div>
            <div class="tw-my-0.5 tw-h-8 tw-w-3/4 standard:tw-w-80">
              <g-skeleton> </g-skeleton>
            </div>
            <div class="tw-my-0.5 tw-h-9 tw-w-3/4 standard:tw-w-80">
              <g-skeleton> </g-skeleton>
            </div>
            <div class="tw-my-0.5 tw-h-8 tw-w-1/2 standard:tw-w-64">
              <g-skeleton> </g-skeleton>
            </div>
          </g-flex>
          <g-flex class="tw-justify-between tw-flex-col tw-flex-auto tw-items-end">
            @if (cartItemMode === CartItemMode.CHECKOUT) {
              <div class="tw-w-7 tw-h-7">
                <g-skeleton> </g-skeleton>
              </div>
            }
            <div class="tw-my-auto">
              <div class="tw-hidden standard:tw-block tw-my-1 tw-h-8 tw-w-20">
                <g-skeleton> </g-skeleton>
              </div>
            </div>
          </g-flex>
        </g-flex>
      </g-flex>
    </g-flex>
  }

  @case (State.Loaded) {
    <g-flex class="tw-flex-col tw-my-4">
      <g-flex class="cart-item tw-flex-col">
        @switch (cartItemMode) {
          @case (CartItemMode.EXPIRING_ISSUE_BASED_SUBSCRIPTION) {
            <g-flex class="tw-flex-col tw-px-4 tw-my-3 tw-justify-between">
              <g-flex class="tw-flex-col standard:tw-flex-row tw-justify-between">
                <g-flex class="tw-justify-between tw-flex-col">
                  <ng-container [ngTemplateOutlet]="header"></ng-container>
                  <ng-container [ngTemplateOutlet]="body"></ng-container>
                </g-flex>
                <fly-add-to-cart-button
                  [presentInCurrentCart]="presentInCurrentCart"
                  [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
                  (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
                </fly-add-to-cart-button>
              </g-flex>
              @if (addToCurrentCartFailed) {
                <g-error class="tw-pt-3">
                  An error occurred while adding this item. Please try again, or contact
                  <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
                </g-error>
              }
              @if (hasCollapsedDetails) {
                <ng-container [ngTemplateOutlet]="collapsedDetails"> </ng-container>
              }
            </g-flex>
          }

          @case (CartItemMode.CONFIRMATION) {
            <g-flex class="tw-flex-col tw-px-4 tw-my-3 tw-justify-between">
              <g-flex class="tw-flex-col standard:tw-flex-row tw-justify-between">
                <g-flex class="tw-justify-between tw-flex-col">
                  <ng-container [ngTemplateOutlet]="header"></ng-container>
                  <ng-container [ngTemplateOutlet]="body"></ng-container>
                </g-flex>
                <g-flex
                  class="tw-flex-col tw-justify-between tw-gap-y-2 standard:tw-justify-center standard:tw-items-end standard:tw-gap-y-1">
                  @if (price != null) {
                    <g-price [originalPrice]="price | flyMoney"></g-price>
                  }
                </g-flex>
              </g-flex>
            </g-flex>
          }

          @case (CartItemMode.CHECKOUT) {
            <g-flex class="tw-flex-col tw-px-4 tw-my-3">
              <g-flex class="tw-flex-row tw-justify-between">
                <g-flex class="tw-justify-between tw-flex-col">
                  <ng-container [ngTemplateOutlet]="removeCartItemFailedMessage"></ng-container>
                  <ng-container [ngTemplateOutlet]="header"></ng-container>
                  <ng-container [ngTemplateOutlet]="body"></ng-container>
                  @if (price != null) {
                    <g-price
                      class="standard:tw-hidden"
                      [originalPrice]="price | flyMoney">
                    </g-price>
                  }
                </g-flex>
                <g-flex class="tw-flex-col tw-items-end tw-justify-between">
                  <ng-container [ngTemplateOutlet]="removeCartItemButton"></ng-container>
                  @if (price != null) {
                    <g-price
                      class="tw-hidden standard:tw-block tw-pb-3 tw-m-auto"
                      [originalPrice]="price.formattedValue">
                    </g-price>
                  }
                </g-flex>
              </g-flex>
              @if (cartItemValidationFailed) {
                <g-error class="tw-pt-3">
                  <p [innerHTML]="yarmouthValidationErrorMessage"></p>
                </g-error>
              }
              @if (hasCollapsedDetails) {
                <ng-container [ngTemplateOutlet]="collapsedDetails"> </ng-container>
              }
            </g-flex>
          }
        }
      </g-flex>
    </g-flex>
  }

  @case (State.Error) {
    <g-flex class="tw-flex-col tw-my-4">
      <g-flex class="cart-item tw-flex-col">
        <g-flex class="tw-flex-row tw-px-4 tw-my-3 tw-justify-between">
          <g-flex class="tw-justify-between tw-flex-col">
            <ng-container [ngTemplateOutlet]="removeCartItemFailedMessage"></ng-container>
            <ng-container [ngTemplateOutlet]="header"></ng-container>
            <g-error class="tw-pt-2">
              <p [innerHTML]="errorMessage"> </p>
            </g-error>
          </g-flex>
          <g-flex class="tw-flex-col tw-items-end tw-justify-between">
            @if (cartItemMode === CartItemMode.CHECKOUT) {
              <ng-container [ngTemplateOutlet]="removeCartItemButton"> </ng-container>
            }
          </g-flex>
        </g-flex>
      </g-flex>
    </g-flex>
  }

  @case (State.Removed) {}
}
