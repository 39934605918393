<ng-template #loading>
  <fly-full-page-loader> </fly-full-page-loader>
</ng-template>

<div>
  <g-flex class="header-container">
    <img
      alt="database subscriptions icon"
      class="database-icon"
      src="assets/img/db.svg" />
    <g-heading
      [content]="'Expiring Subscriptions'"
      size="1"
      class="expiring-subscriptions-heading">
    </g-heading>
  </g-flex>
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }

    @if (status.status === ObservableStatus.ERROR) {
      <div class="error-container">
        <g-heading
          size="2"
          [content]="'We\'re sorry, we encountered an error'">
        </g-heading>
        <g-error>
          Please refresh the page or contact
          <a routerLink="/aviation-support">Aviation Product Support</a>
          if the issue continues.
        </g-error>
      </div>
    }

    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      @if (status.value.totalNumberOfExpiringIssuedBasedSubscriptions > 0) {
        <g-flex class="expiring-subscriptions-container">
          <div class="expiring-subscriptions-width">
            <g-flex class="expiring-subscriptions-info-section">
              <p class="expiring-subscription-message">
                The following subscriptions are expiring soon. To renew, simply add these items to your cart and
                checkout.
              </p>
              @if (autoRenewalEnabled) {
                <p>
                  <strong>Note:</strong> Annual subscriptions now cover a full calendar year instead of a specific
                  number of updates. Annual subscriptions will automatically renew each year until you turn off the
                  auto-renewal setting in FlyGarmin on the Subscriptions page. One-time purchases will now provide
                  coverage for one month and will not auto-renew.
                </p>
              }
            </g-flex>

            @for (
              onePaks of status.value.expiringIssueBasedAircraftBundleSubscriptionsGroupedByAircraft | keyvalue;
              track onePaks
            ) {
              <fly-cart-items-avdb-bundles
                [cartItemMode]="cartItemMode"
                [bundleType]="AvdbPricingBundleType.ONEPAK"
                [skuProductsMap]="status.value.skuProductsMap"
                [legacySubscriptionExpiringResponses]="onePaks.value"
                [aircraftId]="onePaks.key"
                [currentCartCategorized]="status.value.currentCart">
              </fly-cart-items-avdb-bundles>
            }
            @for (
              devideBundles of status.value.expiringIssueBasedDeviceBundleSubscriptionsGroupedByDevice | keyvalue;
              track devideBundles
            ) {
              <fly-cart-items-avdb-bundles
                [cartItemMode]="cartItemMode"
                [bundleType]="AvdbPricingBundleType.DEVICE"
                [skuProductsMap]="status.value.skuProductsMap"
                [legacySubscriptionExpiringResponses]="devideBundles.value"
                [deviceId]="devideBundles.key"
                [currentCartCategorized]="status.value.currentCart">
              </fly-cart-items-avdb-bundles>
            }
            @for (avdb of status.value.expiringIssuedBasedAvdbSubscriptions; track avdb.id) {
              <fly-cart-item-avdb
                [cartItemMode]="cartItemMode"
                [skuProduct]="status.value.skuProductsMap.get(avdb.sku)"
                [legacySubscriptionExpiringResponse]="avdb"
                [currentCartCategorized]="status.value.currentCart">
              </fly-cart-item-avdb>
            }
          </div>

          <g-flex class="cart-btn-container">
            <g-button
              size="medium"
              theme="dark"
              (click)="navigateToCartPage()">
              GO TO CART
            </g-button>
          </g-flex>
        </g-flex>
      } @else {
        <div class="no-expiring-subscriptions-message">
          <g-heading
            size="2"
            [content]="'You do not have expiring subscriptions in the next ' + daysFromToday + ' days'">
          </g-heading>
          @if (autoRenewalEnabled) {
            <p>
              To manage your subscriptions, please navigate to the
              <a routerLink="/subscriptions/databases">Subscriptions</a> page.
            </p>
          }
        </div>
      }
    }
  }
</div>
