import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Signal,
  SimpleChanges
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { EMPTY, Observable, switchMap, tap } from 'rxjs';
import { UserConfig } from '../../../../../core/services/config/user-config.service';
import { ObservableStatus } from '../../../../../shared/enums/observable-status-enum';
import { CartAddItemRequest } from '../../../../../shared/models/cart/cart-add-item-request';
import { Cart } from '../../../../../shared/models/cart/cart.model';
import { DiscountCodeCartItem } from '../../../../../shared/models/cart/discount-code-cart-item';
import { Money } from '../../../../../shared/models/money.model';
import { BillingAccount } from '../../../../../shared/models/subscriptions/billing-account.model';
import { AccountsService } from '../../../../../shared/services/accounts.service';
import { CartValidationService } from '../../../../../shared/services/cart-validation.service';
import { CartService } from '../../../../../shared/services/cart.service';

enum State {
  Loading,
  Loaded,
  AccountLoadError,
  SubtotalCalculationError,
  InvalidDiscountCodeError,
  CartItemsProcessError,
  CartItemsValidationError,
  CartProcessError
}

@Component({
  selector: 'fly-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnChanges, OnInit {
  @Input() subtotal: { sum: Money; error?: string } | null;
  @Input() foundCartItemsError: boolean;
  @Input() numberOfCartItemsBeingRemoved: number;
  @Input() discountCodeCart: DiscountCodeCartItem[] = [];
  @Input({ required: true }) userConfig: UserConfig;
  @Input({ required: true }) fleetUserErrorMessage: Signal<string | null>;
  @Output() readonly addDiscountCodeCartItem: EventEmitter<DiscountCodeCartItem> = new EventEmitter();
  @Output() readonly checkout: EventEmitter<string> = new EventEmitter();

  readonly State = State;
  cartForm: FormGroup = new FormGroup([]);
  codeForm = this.formBuilder.group(
    { discountCode: ['', Validators.required] },
    { validators: this.discountCodeIsAlreadyInCartValidator() }
  );
  currentState: State = State.Loading;
  accounts: BillingAccount[];
  accountsError: boolean;
  addingDiscountCodeCartItem: boolean = false;
  showBillingAccountSelector: boolean = false;
  checkoutDisabled: boolean = false;
  readonly ObservableStatus = ObservableStatus;

  private validationError: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly accountsService: AccountsService,
    private readonly cartService: CartService,
    private readonly cartValidationService: CartValidationService,
    private readonly destroyRef: DestroyRef,
    private readonly featuresService: FeaturesService
  ) {}

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.AR_CHECKOUT_VALIDATION)
      .pipe(
        tap((active) => (this.showBillingAccountSelector = active)),
        switchMap((active) => {
          if (active) {
            return this.accountsService.getAccounts();
          } else {
            this.updateState();
            return EMPTY;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (accounts) => {
          this.handleAccountsSuccess(accounts);
        },
        error: this.handleAccountsError.bind(this)
      });

    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.DISABLE_CHECKOUT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((disabled) => {
        this.checkoutDisabled = disabled;
      });

    this.cartValidationService
      .checkForCartError()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cartHasError) => (this.validationError = cartHasError));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.foundCartItemsError) {
      this.currentState = State.CartItemsProcessError;
    } else if ('subtotal' in changes && !this.foundCartItemsError) {
      this.updateState();
    }

    if ('discountCodeCart' in changes) {
      this.codeForm.controls.discountCode.updateValueAndValidity();
    }
  }

  addDiscountCodeToCart(): Observable<Cart>;
  addDiscountCodeToCart(events: Event): void;
  addDiscountCodeToCart(events?: Event): Observable<Cart> | void {
    this.addingDiscountCodeCartItem = true;
    const code = this.codeForm.controls.discountCode.value ?? undefined;
    const codeItem: CartAddItemRequest = {
      itemType: CartItemType.DISCOUNT_CODE,
      code
    };

    const addToCart$ = this.cartService.addItemToCurrentCart(codeItem);
    const addToCartCallback = (cart: Cart): void => {
      const discountCodeCartItem = cart.promoCodeCart.find((cartItem) => cartItem.code === codeItem.code);
      this.addDiscountCodeCartItem.emit(discountCodeCartItem);
      this.addingDiscountCodeCartItem = false;
    };

    if (events != null) {
      addToCart$.subscribe(addToCartCallback);
    }

    this.codeForm.controls.discountCode.setValue('');

    if (events == null) {
      return addToCart$.pipe(tap(addToCartCallback));
    }
  }

  getErrorMessage(state: State): string | null {
    switch (state) {
      case State.AccountLoadError:
        return (
          'An error occurred loading billing information for your account. ' +
          'Please try again in a few minutes or contact <a routerLink="/aviation-support">Aviation Product Support</a> for assistance.'
        );
      case State.SubtotalCalculationError:
        return (
          'An error occurred while calculating the subtotal. ' +
          'Please remove the item(s) and try again, ' +
          'or contact <a routerLink="/aviation-support">Aviation Product Support</a> ' +
          'if the issue continues.'
        );
      case State.InvalidDiscountCodeError:
        return 'One or more of the provided discount codes are invalid. Please verify or remove the code(s) to checkout.';
      case State.CartItemsProcessError:
        return (
          'An error occurred while processing one or more items in your cart. ' +
          'Please remove the item(s) and try again, ' +
          'or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.'
        );
      case State.CartItemsValidationError:
        return (
          'An error occurred while validating your cart. ' +
          'If there are any items with errors displayed, please remove them and try again, ' +
          'or contact <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.'
        );
      case State.CartProcessError:
        return (
          'An error occurred, and we were unable to process your order. ' +
          'Please contact <a routerLink="/aviation-support">Aviation Product Support</a> for assistance.'
        );
      default:
        return null;
    }
  }

  handleAccountsSuccess(accounts: BillingAccount[]): void {
    this.accounts = accounts;
    this.updateState();
  }

  handleAccountsError(): void {
    this.accountsError = true;
    this.updateState();
  }

  discountCodeIsAlreadyInCartValidator(): ValidatorFn {
    return (control): ValidationErrors | null => {
      const discountCodeControl = control.get('discountCode');

      if (discountCodeControl == null) {
        return null;
      }
      const discountCodeInput = discountCodeControl.value;

      if (
        this.discountCodeCart.some(
          (discountCodeCartItem) => discountCodeCartItem.code.toUpperCase() === discountCodeInput.toUpperCase()
        )
      ) {
        discountCodeControl.setErrors({ discountCodeInCartAlready: true });
        return { discountCodeInCartAlready: true };
      }
      return null;
    };
  }

  onCheckout(): void {
    const code = this.codeForm.controls.discountCode.value;
    if (isStringNonEmpty(code)) {
      this.addDiscountCodeToCart()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.checkout.emit(this.cartForm.get('billingAccountSelect')?.value ?? null);
          },
          error: (e) => {
            console.error(e);
          }
        });
    } else {
      this.checkout.emit(this.cartForm.get('billingAccountSelect')?.value ?? null);
    }
  }

  private updateState(): void {
    const subtotalLoaded = this.subtotal != null && this.subtotal.error == null;
    const subtotalError = this.subtotal != null && this.subtotal.error != null;
    const accountsLoaded = this.accounts != null && !this.accountsError;

    if (subtotalLoaded && (accountsLoaded || !this.showBillingAccountSelector)) {
      this.currentState = State.Loaded;
    } else if (subtotalError) {
      this.currentState = State.SubtotalCalculationError;
    } else if (this.accountsError) {
      this.currentState = State.AccountLoadError;
    } else if (this.foundCartItemsError) {
      this.currentState = State.CartItemsProcessError;
    } else if (!subtotalLoaded && !this.accountsError) {
      this.currentState = State.Loading;
    }

    if (this.validationError && subtotalLoaded) {
      this.currentState = State.CartItemsValidationError;
    }
  }
}
