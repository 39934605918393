import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { SkuProduct } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CartItemMode } from '../../../../../shared/enums/cart-item-mode-enum';
import { ObservableStatus } from '../../../../../shared/enums/observable-status-enum';
import { CategorizedCart } from '../../../../../shared/models/cart/categorized-cart.model';
import { Order } from '../../../../../shared/models/order.model';
import { CartService } from '../../../../../shared/services/cart.service';
import { OrdersService } from '../../../../../shared/services/orders.service';
import { SkuService } from '../../../../../shared/services/sku.service';

@Component({
  selector: 'fly-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  readonly ObservableStatus = ObservableStatus;
  protected gpAutoRenewalEnabled = false;
  cartItemMode: CartItemMode = CartItemMode.CONFIRMATION;
  asyncData$: Observable<{
    categorizedCart: CategorizedCart;
    skuProductsMap: Map<string, SkuProduct>;
    orderInfo: Order;
  }>;
  orderToken: string;
  displayAutoRenewalMessage: boolean = false;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    readonly cartService: CartService,
    readonly ordersService: OrdersService,
    readonly skuService: SkuService,
    private readonly route: ActivatedRoute,
    private readonly featuresService: FeaturesService
  ) {
    this.orderToken = this.route.snapshot.queryParams.orderToken;
  }

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.GP_AUTO_RENEWALS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.gpAutoRenewalEnabled = enabled));

    if (this.orderToken != null) {
      const cartAndSkuProductsMap$ = forkJoin({
        autoRenewalMessageFeatureFlagActive: this.featuresService.isFeatureActive(
          FeatureFlyGarmin.AR_CONFIRMATION_MESSAGE
        ),
        cart: this.cartService.getCartAndOrderInfoByOrderToken(this.orderToken)
      }).pipe(
        mergeMap((data) => {
          const { cart, autoRenewalMessageFeatureFlagActive } = data;
          const skusToFetch = [
            ...cart.avdbCart.map((item) => item.product.partKey),
            ...cart.avdbBundleCart.map((item) => item.bundle.partKey),
            ...cart.enablementCart.map((item) => item.product.partKey),
            ...cart.gpCart.map((item) => item.product.partNumber)
          ];

          return this.skuService.skuProductsMap$(skusToFetch).pipe(
            map((skuProductsMap) => {
              const categorizedCart = this.cartService.categorizeCartItems(cart);

              if (
                autoRenewalMessageFeatureFlagActive &&
                [
                  categorizedCart.aircraftBundlesCart.size,
                  categorizedCart.deviceBundlesCart.size,
                  categorizedCart.avdbCart.length
                ].some((size) => size > 0)
              ) {
                this.displayAutoRenewalMessage = true;
              }

              return { skuProductsMap, categorizedCart };
            })
          );
        })
      );

      const orderInfo$ = this.ordersService.getOrderInformation(this.orderToken);
      const completeOrder$ = this.cartService.completeCartByOrderToken(this.orderToken);

      this.asyncData$ = combineLatest([cartAndSkuProductsMap$, orderInfo$, completeOrder$]).pipe(
        map(
          ([cartAndSkuProductsMap, orderInfo]): {
            categorizedCart: CategorizedCart;
            skuProductsMap: Map<string, SkuProduct>;
            orderInfo: Order;
          } => ({
            categorizedCart: cartAndSkuProductsMap.categorizedCart,
            skuProductsMap: cartAndSkuProductsMap.skuProductsMap,
            orderInfo: orderInfo
          })
        )
      );
    }
  }
}
