import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/pages/cart/cart.component';
import { ConfirmationComponent } from './cart/pages/confirmation/confirmation.component';
import { PurchasingMenuComponent } from './cart/pages/purchasing-menu/purchasing-menu.component';
import { ExpiringSubscriptionsComponent } from './expiring-subscriptions/pages/expiring-subscriptions.component';

const routes: Routes = [
  {
    path: '',
    component: PurchasingMenuComponent,
    children: [
      {
        path: 'cart',
        title: 'Cart',
        component: CartComponent
      },
      {
        path: 'confirm',
        title: 'Order Confirmation',
        component: ConfirmationComponent
      },
      {
        path: 'expiring-subscriptions',
        title: 'Expiring Subscriptions',
        component: ExpiringSubscriptionsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchasingRoutingModule {}
