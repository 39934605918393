@if (currentState === State.Promoting) {
  @if (numberOfAircraftToPromote > 0) {
    <div class="text-container">
      Verifying {{ aircraftCompletionCounter }} of {{ numberOfAircraftToPromote }} aircraft in the cart
    </div>
  }
}

@if (currentState === State.Error) {
  @if (aircraftTailsThatFailedToPromote.length > 0) {
    <div class="text-container">
      <p class="error-message"
        >We were not able to verify your aircraft with the
        <span [innerHTML]="failedAircraftPromotionDisplay"></span>.</p
      >
      <p class="error-message"
        >Please verify that your aircraft tail number, serial number, and device serial numbers are correct.</p
      >
      <p>If errors persist, contact <a [routerLink]="['/aviation-support']">Aviation Product Support</a>.</p>
    </div>
  }
  @if (aircraftTailsThatFailedToPromote.length === 0) {
    <div class="text-container">
      <p class="error-message">An error occurred while preparing your cart. Please refresh the page and try again.</p>
      <p>If errors persist, contact <a [routerLink]="['/aviation-support']">Aviation Product Support</a>.</p>
    </div>
  }
}
