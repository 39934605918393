<ng-template #loading>
  <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
</ng-template>

<ng-template #error>
  <fly-cart-item
    [cartItem]="item"
    [cartItemType]="cartItemType"
    [hasError]="hasError"
    [headerText]="skuProduct?.display ?? item.product.partNumber"
    [cartItemMode]="cartItemMode"
    [removeCartItem$]="removeCartItem$"
    (removeCartItemEvent)="removeCartItem.emit($event)"
    (removingCartItem)="removingCartItem.emit($event)">
  </fly-cart-item>
</ng-template>

<ng-template #duration>
  @if (skuProduct != null) {
    <strong>Duration:</strong> {{ skuProduct.duration }}
  }
</ng-template>

@if (!hasError && skuProduct != null) {
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
    @if (status.status === ObservableStatus.ERROR) {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      <fly-cart-item
        [skuProduct]="skuProduct"
        [price]="status.value.price"
        [cartItem]="item"
        [hasError]="hasError"
        [cartItemType]="cartItemType"
        [headerText]="skuProduct.display"
        [cartItemMode]="cartItemMode"
        [hasCollapsedDetails]="status.value.garminPilotProduct != null && skuProduct.coverageDescriptionHTML != null"
        [removeCartItem$]="removeCartItem$"
        [kindOfCollapsedDetails]="'Subscription'"
        (expandCollapsedDetailsEvent)="expandCollapsedSubscriptionDetails = $event"
        (removeCartItemEvent)="removeCartItem.emit($event)"
        (removingCartItem)="removingCartItem.emit($event)">
        <div body>
          <p>
            @if (item.product.subscriptionType.maxDeviceCount === 1) {
              <span> Supports {{ item.product.subscriptionType.maxDeviceCount }} device. </span>
            }
            @if (item.product.subscriptionType.maxDeviceCount > 1) {
              <span> Supports up to {{ item.product.subscriptionType.maxDeviceCount }} devices. </span>
            }
          </p>
          <p>
            @if (additionalInfo != null) {
              {{ additionalInfo }}
            } @else {
              @if (skuProduct != null) {
                <strong>Duration:</strong> {{ skuProduct.duration }}
              }
            }
          </p>
          @if (item.existingSubscriptionUuid == null && cartItemMode === CartItemMode.CHECKOUT && futureStartEnabled) {
            <p>
              <strong>Start Date: </strong>
              @if (item.futureStartDate == null) {
                Today
              } @else {
                {{ item.futureStartDate | flyFormattedDate }}
              }
              <button
                class="link-styled-button"
                (click)="changeStartDateModal.open()">
                Change
              </button>
            </p>
          }
          <p> <strong>Part Number:</strong> {{ skuProduct.sku }} </p>
          @if (item.existingSubscriptionUuid != null && subscriptionsEarlyRenewalEnabled) {
            @if (cartItemMode === CartItemMode.CHECKOUT && newExpiration != null) {
              <p> This purchase will extend your existing subscription to {{ newExpiration | flyFormattedDate }}. </p>
            } @else if (cartItemMode === CartItemMode.CONFIRMATION) {
              <br />
              <p>Your existing subscription has been extended by <strong>1 Year</strong>.</p>
              <p>This may take a few minutes to be displayed.</p>
            }
          }
        </div>

        @if (skuProduct != null) {
          <div collapsedDetails>
            @if (expandCollapsedSubscriptionDetails) {
              <fly-coverage-details-mobile-device
                [detailsHTML]="skuProduct.coverageDescriptionHTML"
                [supportedPlatforms]="skuProduct.supportedPlatforms">
              </fly-coverage-details-mobile-device>
            }
          </div>
        }
      </fly-cart-item>
    }
  }
} @else {
  <ng-container [ngTemplateOutlet]="error"></ng-container>
}

<fly-cart-item-change-start-date-modal
  #changeStartDateModal
  [item]="item"
  [itemType]="'gp'"></fly-cart-item-change-start-date-modal>
