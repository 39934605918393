<ng-template #loading>
  <fly-cart-item [cartItemMode]="cartItemMode"> </fly-cart-item>
</ng-template>

<ng-template #error>
  <fly-cart-item
    [cartItem]="item"
    [cartItemType]="cartItemType"
    [headerText]="skuProduct?.display ?? item.product.partKey"
    [cartItemMode]="cartItemMode"
    [hasError]="hasError"
    [removeCartItem$]="removeCartItem$"
    (removeCartItemEvent)="removeCartItem.emit($event)"
    (removingCartItem)="removingCartItem.emit($event)">
  </fly-cart-item>
</ng-template>

@if (!hasError && skuProduct != null) {
  @if (asyncData$ | flyObservableStatus | async; as status) {
    @if (status.status === ObservableStatus.LOADING) {
      <ng-container [ngTemplateOutlet]="loading"></ng-container>
    }
    @if (status.status === ObservableStatus.ERROR) {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
    @if (status.status === ObservableStatus.LOADED && status.value != null) {
      <fly-cart-item
        [skuProduct]="skuProduct"
        [price]="status.value.price"
        [cartItem]="item"
        [cartItemType]="cartItemType"
        [headerText]="skuProduct.display"
        [cartItemMode]="cartItemMode"
        [removeCartItem$]="removeCartItem$"
        (removingCartItem)="removingCartItem.emit($event)"
        (removeCartItemEvent)="removeCartItem.emit($event)">
        <div body>
          <p>
            <strong>Covered Device: </strong>
            <a href="{{ flyLegacyDeviceDetailsUrl }}"> {{ item.device.name }} ({{ item.device.displaySerial }}) </a>
          </p>
          <strong>Part Number: </strong>{{ skuProduct.sku }}
        </div>
      </fly-cart-item>
    }
  }
} @else {
  <ng-container [ngTemplateOutlet]="error"></ng-container>
}
