import { Component, Input } from '@angular/core';
import { AvdbPricingBundleType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { AvdbPricingDeviceBundle } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-device-bundle.model';
import { AvdbPricingDeviceModel } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-device-model.model';
import { AvdbPricingResponse } from '../../../../../../shared/models/avdb-pricing/avdb-pricing-response.model';

@Component({
  selector: 'fly-coverage-details-avdb-bundle',
  templateUrl: './coverage-details-avdb-bundle.component.html'
})
export class CoverageDetailsAvdbBundleComponent {
  @Input({ required: true }) bundleType: AvdbPricingBundleType;
  @Input({ required: true }) avdbPricingResponse: AvdbPricingResponse;
  @Input() deviceBundle: AvdbPricingDeviceBundle;
  @Input() qualifyingDeviceModelsOnAircraft: AvdbPricingDeviceModel[];

  protected readonly AvdbPricingBundleType = AvdbPricingBundleType;
}
