<fly-cart-item
  [cartItem]="item"
  [cartItemType]="cartItemType"
  [hasError]="hasError"
  [cartItemMode]="cartItemMode"
  [removeCartItem$]="removeCartItem$"
  (removeCartItemEvent)="removeDiscountCodeCartItem.emit($event)"
  (removingCartItem)="removingCartItem.emit($event)">
  <div header>
    <h2 class="tw-m-0 tw-mb-1">
      {{ item.code }}
    </h2>
  </div>
  <div body>
    <p> Updated price will be reflected at checkout. </p>
  </div>
</fly-cart-item>
