@if (matchingDevice != null && (deviceModel != null || deviceBundle != null)) {
  @if (bundleType !== AvdbPricingBundleType.DEVICE) {
    <h2 class="device-name-header">
      {{ matchingDevice.name }}
    </h2>
  }

  <ng-container>
    @for (avdbType of avdbTypes; track avdbType.id; let last = $last) {
      <div
        class="avdb-type-container"
        [ngClass]="{
          'avdb-type-border': !last,
          'avdb-type-last': last,
          'avdb-type-indent': bundleType !== AvdbPricingBundleType.DEVICE
        }">
        <h3 class="avdb-type-name-header">{{ avdbType.name }}</h3>
        @for (productOption of avdbType.productOptions; track productOption; let isLast = $last) {
          <fly-coverage-details-avdb-bundle-info
            [avdbTypeId]="avdbType.id"
            [productPartKey]="productOption"
            [device]="matchingDevice">
          </fly-coverage-details-avdb-bundle-info>
          @if (!isLast) {
            <h3>- OR -</h3>
          }
        }
      </div>
    }
  </ng-container>
} @else {
  <h2 class="coverage-unavailable-text">Coverage Details Unavailable</h2>
}
